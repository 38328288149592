import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import type { Plugin } from 'vue';
import { IS_LOCAL_ENV } from './environment';

const NO_OP = () => { };

export const startBugsnag = () => {
    if (IS_LOCAL_ENV || Bugsnag.isStarted()) {
        return;
    }

    Bugsnag.start({
        apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
        plugins: [new BugsnagPluginVue()],
        releaseStage: import.meta.env.VITE_BUGSNAG_RELEASE_STAGE,
    });

    window.Bugsnag = Bugsnag;
}

export const getBugsnagVuePlugin = (): Plugin => {
    if (IS_LOCAL_ENV) {
        return NO_OP;
    }

    if (!Bugsnag.isStarted()) {
        startBugsnag();
    }

    return Bugsnag.getPlugin('vue');
};
